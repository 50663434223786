import React from 'react';
import Helmet from 'react-helmet';

const baseUrl = 'https://seya.dev/';

const description = 'seya のブログです。';

const logoImg = baseUrl + 'images/icon.png';

export const SEO = props => {
  const title = 'Seya Blog';

  return (
    <Helmet>
      <meta name="description" content={description} />

      <meta property="og:title" content={title} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={baseUrl} />
      <meta property="og:image" content={logoImg} />
      <meta property="og:site_name" content={title} />
      <meta property="og:description" content={description} />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={logoImg} />
    </Helmet>
  );
};
